import api from './api'
export async function getServices(category, page) {

    try {
        let params = {}
        if (page) params.page = page
        if (category) params.category = category
        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        const data = await api.get(`${process.env.API_URL}/services?${query}`)
            .then(res => {
                return res.data
            })
            .catch(err => {
                console.log(err);
                return []
            })
        return data
    } catch (error) {
        return []
    }
}

export async function getService(slug) {
    const data = await api.get(`${process.env.API_URL}/service?slug=${slug}`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data

}

export async function getAllServices() {
    const data = await api.get(`${process.env.API_URL}/allServices`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data

}
export async function getDiscounts(cart) {
    const data = await api.post(`${process.env.API_URL}/cartDiscounts`, {'cart': cart})
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data

}
export async function getSeats(zone_id, permanent_booking_id) {
    const data = await api(`${process.env.API_URL}/getAvailableSeats`, {
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            zone_id: zone_id,
            permanent_booking_id: permanent_booking_id,
        },
    })
        .then((response) => {

            return response.data.seats;

        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data

}