import React from 'react'
import { Link } from 'gatsby'
import course1 from '../../assets/images/courses/course1.jpg'
import { format } from 'date-fns'
import {getConversion} from '../../api/images'

const ServiceCard = ({ service }) => {
    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })
    const renderPrice = (service) => {
        switch (service.price_type) {
            case "fixed":
                return <>
                    <div className='price shadow'>{service.price == 0 ? 'Gratis' : formatter.format(service.price)}</div>

                </>;
            case "free":
                return <>
                    &nbsp;
                </>;
            case "percentage":
                return <>
                    <div className='price shadow'>{service.price} %</div>

                </>;
            default:
                return <>
                    <div className='price shadow'>{formatter.format(service.price)}</div>
                </>;
        }
    };
    return (
        <div className='col-12 col-md-6 col-lg-4 col-xl-3 '>
            <div className='single-services-box'>
                <div className='services-image'>
                    <Link to={`/service-details?slug=${service.slug}`} className='d-block image'>
                        <img src={service.media[0] ? getConversion(service.media[0]) : course1} alt='about' />
                    </Link>
                    <Link to={`/service-details?slug=${service.slug}`} className='fav'>
                        <i className='flaticon-heart'></i>
                    </Link>
                    {renderPrice(service)}
                </div>
                <div className='services-content'>
                    <h3>
                        <Link to={`/service-details?slug=${service.slug}`}>
                            {service.name}
                        </Link>
                    </h3> 
                </div>
            </div>
        </div>
    )
}

export default ServiceCard